import ProgrammingPill from "./ProgrammingPill";

const languagesOrder = [
  "Python",
  "Machine Learning",
  "TypeScript",
  "Node.js",
  "Java",
  "C++",
  "Docker",
  "Kubernetes",
  "Shell",
  "React",
  "Express",
  "JavaScript",
  "PHP",
  "Laravel",
  "HTML",
  "CSS",
  "JavaFX",
  "Arduino",
  "C",
  "SQL",
  "Bootstrap",
  "p5.js",
  "jQuery",
];

const ProgrammingPills = ({ languages }: { languages: string[] }) => {
  return (
    <>
      {
        /* Sorted Languages */
        languages
          .filter((language) => languagesOrder.includes(language))
          .sort((a, b) => languagesOrder.indexOf(a) - languagesOrder.indexOf(b))
          .map((language) => ProgrammingPill({ language: language }))
      }
      {
        /* Other Languages */
        languages
          .filter((language) => !languagesOrder.includes(language))
          .sort()
          .map((language) => ProgrammingPill({ language: language }))
      }
    </>
  );
};

export default ProgrammingPills;
