import projectsJson from "../../data/projects.json";
import ProjectsMasonry from "./ProjectsMasonry";

export interface ProjectLink {
  website: string;
  link: string;
}

export interface Project {
  name: string;
  featured?: boolean;
  slug: string;
  year: number;
  image: string;
  description: string;
  languages: string[];
  links?: ProjectLink[];
  screenshots?: { caption: string; link: string }[];
}

const projects: Project[] = projectsJson;

const Projects = () => {
  return (
    <>
      <h2 className="page-heading text-center mt-0 mb-3">⎯ Featured ⎯</h2>
      <ProjectsMasonry projects={projects.filter(({ featured }) => featured)} />
      <h2 className="page-heading text-center my-3">All Projects</h2>
      <ProjectsMasonry projects={projects} />
    </>
  );
};

export default Projects;
