import { Outlet } from "react-router-dom";
import ReactGA from "react-ga4";

import Navbar from "./Navbar";
import Footer from "./Footer";

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import("bootstrap");

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("G-W32SSS26VF");
}

const App = () => {
  return (
    <div className="container-lg">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default App;
