import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { faGithubAlt, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import("bootstrap");

const Footer = () => {
  return (
    <footer className="d-flex flex-wrap justify-content-between pt-2 mt-4 mb-1 border-top">
      <div className="col-md-4 d-flex align-items-center">
        <span className="mb-1 text-body-secondary">
          <FontAwesomeIcon icon={faCopyright} /> 2024 Neuton Foo
        </span>
      </div>
      <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
        <li></li>
        <li>
          <a
            className="text-body-secondary"
            href="https://github.com/neutonfoo"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faGithubAlt} size="2x" />
          </a>
        </li>
        <li className="ms-2">
          <a
            className="text-body-secondary"
            href="https://www.linkedin.com/in/neutonfoo/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
