import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "./ProjectsMasonry.scss";
import { Project, ProjectLink } from "./Projects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Fancybox from "../components/Fancybox";
import ProgrammingPills from "../components/ProgrammingPills";

const formatLink = (projectLink: ProjectLink) => {
  const website = projectLink.website;
  const link = projectLink.link;

  let color = "";

  switch (website) {
    case "github":
      color = "blue";
      break;
    case "devpost":
      color = "orange accent-2 darken-4";
      break;
    case "demo":
      color = "yellow black-text";
      break;
    case "live":
      color = "green accent-4";
      break;
    default:
      break;
  }

  return (
    <a
      key={link}
      rel="noopener noreferrer"
      href={link}
      className={`btn btn-sm link me-2 ${color} shadow text-uppercase`}
      target="_blank"
    >
      {website}
    </a>
  );
};

const ProjectsMasonry = ({ projects }: { projects: Project[] }) => {
  return (
    <Fancybox>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 0: 1, 576: 2, 992: 3, 1400: 4 }}
      >
        <Masonry className="row" style={{ width: "inherit" }}>
          {projects
            .slice()
            .reverse()
            .map((project) => (
              <div className="col-12 mx-1 mb-2 p-1" key={`${project.slug}`}>
                <div className="card rounded-0 project-card">
                  <div className="thumbnail">
                    <div className="screenshots">
                      <a
                        data-fancybox="gallery"
                        href={`/assets/projects/${project.image}`}
                      >
                        {project.image.split(".").pop() === "mp4" ? (
                          <video
                            autoPlay={true}
                            playsInline={true}
                            muted
                            loop
                            width="100%"
                            src={`/assets/projects/${project.image}`}
                          />
                        ) : (
                          <img
                            src={`/assets/projects/${project.image}`}
                            alt={project.name}
                          />
                        )}
                      </a>
                    </div>
                    {project.screenshots && (
                      <div className="screenshots d-none">
                        {project.screenshots.map((screenshot) => {
                          const caption = screenshot.caption;
                          const link = screenshot.link;

                          return (
                            <a
                              key={link}
                              data-fancybox="gallery"
                              href={`/assets/projects/${link}`}
                              data-caption={caption}
                            >
                              <img
                                src={`/assets/projects/${link}`}
                                alt={caption}
                              />
                            </a>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div
                    className={`card-body pt-1 pb-1${
                      project.featured ? " blue-grey darken-4 text-white" : ""
                    }`}
                  >
                    {project.screenshots && (
                      <div
                        className={`screenshots-indicator text-end mr-0 pr-0 ${
                          project.featured ? "grey-text" : "text-secondary"
                        }`}
                      >
                        screenshot 1 of {project.screenshots.length + 1}
                      </div>
                    )}
                    <div
                      className={`project-name mb-2 text-center ${
                        project.screenshots ? "mt-1" : "mt-2"
                      }`}
                    >
                      {project.name}
                    </div>
                    <div
                      className={`year mb-2 text-center${
                        project.featured ? "" : " text-secondary"
                      }`}
                    >
                      {project.year}
                    </div>
                    <div className="description px-1 text-center text-sm-start">
                      {project.featured ? (
                        <FontAwesomeIcon
                          icon={faStar}
                          className="yellow-text text-lighten-2"
                          beat
                        ></FontAwesomeIcon>
                      ) : (
                        <></>
                      )}{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: project.description,
                        }}
                      ></span>
                    </div>
                    <div className="mt-2 mb-0 text-center">
                      <ProgrammingPills languages={project.languages} />
                    </div>
                  </div>
                  {project.links && (
                    <div
                      className={`card-footer rounded-0 p-2 text-center text-md-start${
                        project.featured ? " blue-grey darken-4 text-white" : ""
                      }`}
                    >
                      {project.links.map((link) => formatLink(link))}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </Masonry>
      </ResponsiveMasonry>
    </Fancybox>
  );
};

export default ProjectsMasonry;
