import { useEffect, useState } from "react";
import ProgrammingPills from "../components/ProgrammingPills";
import WorkExperience from "./WorkExperience";

const Me = () => {
  const [useHyphensInWorkExperience, setUseHyphensInWorkExperience] =
    useState<boolean>(false);

  useEffect(() => {
    const toggleHyphensInWorkExperience = (e: KeyboardEvent) => {
      if (e.key === "-") {
        setUseHyphensInWorkExperience(!useHyphensInWorkExperience);
      }
    };

    window.addEventListener("keydown", toggleHyphensInWorkExperience);

    return () =>
      window.removeEventListener("keydown", toggleHyphensInWorkExperience);
  });

  return (
    <div className="container-md me">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <div className="bg-white border pt-3 px-3 pb-0">
            <p>Hi, I&apos;m Neuton.</p>
            <p>
              Here&apos;s a link to my{" "}
              <a
                href="https://drive.google.com/file/d/14Spt-Eg3j2XhZheM5QHeHwXG4Uta5h0H/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                resume
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <h2 className="page-heading text-center my-3">Education</h2>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <div className="bg-white border p-3">
            <strong>University of Southern California</strong>
            <em className="text-secondary d-block d-md-inline">
              {" "}
              - Viterbi School of Engineering
            </em>
            <div className="row my-3">
              <div className="col-6 col-md-8">
                <div className="ps-md-5">
                  Bachelor of Science, Computer Science
                </div>
              </div>
              <div className="col-6 col-md-4 text-center">
                <em>
                  Aug 2017 —<span className="d-block d-sm-inline"></span> May
                  2021
                </em>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6 col-md-8">
                <div className="ps-md-5">
                  Master of Science, Computer Science
                </div>
              </div>
              <div className="col-6 col-md-4 text-center">
                <em>
                  Aug 2021 —<span className="d-block d-sm-inline"></span> May
                  2022
                </em>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8 col-xl-4">
          <h2 className="page-heading text-center my-3">Programming</h2>
          <div className="bg-white border pt-3 pb-2 px-3 text-center">
            <ProgrammingPills
              languages={[
                "Python",
                "C++",
                "Java",
                "Node.js",
                "React",
                "HTML",
                "CSS",
                "JavaScript",
                "jQuery",
                "PHP",
                "SQL",
                "Linux CLI",
                "Shell",
                "Docker",
                "Kubernetes",
                "AWS",
                "GCP",
                "Machine Learning",
              ]}
            />
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-4">
          <h2 className="page-heading text-center my-3">Certifications</h2>
          <div className="bg-white border text-center px-3 pt-3 pb-2">
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 green white-text">
              AWS Certified Solutions Architect — <em>Associate</em>
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 red white-text">
              DeepLearning.AI — <em>Deep Learning Specialization</em>
            </div>
          </div>
        </div>
      </div>
      <h2 className="page-heading text-center my-3">Involvement</h2>
      <div className="row justify-content-center text-center">
        <div className="col-12 col-lg-8">
          <div className="bg-white border px-3 pt-3 pb-2">
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 pink lighten-2 white-text">
              🏳️‍🌈 SF Pride Parade 2019, 2021 — <em>Event Volunteer</em>
            </div>
            <br />
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 orange darken-2 white-text">
              🌏 Etsy Asian Resource Group ERG 2024 — <em>Co-Lead</em>
            </div>
            <hr className="mt-2 p-0" />
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 deep-purple white-text">
              🔭 USC Scope
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 red accent-4 white-text">
              🤖 USC Makers
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 blue lighten-4 black-text">
              📝 USC SplashSC
            </div>
            <span className="d-block"></span>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 cyan lighten-1 black-text">
              🌏 USC International Students Association
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 grey lighten-2 red-text text-darken-2">
              🇸🇬 USC Singapore Students Association
            </div>
            <span className="d-block"></span>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 red darken-4 white-text">
              🌲 TreeHacks 2018 — <em>Hacker</em>
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 orange white-text">
              🐴 TrojanHacks 2018 — <em>Hacker</em>
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 yellow darken-2 grey-text text-darken-3">
              🏖️ Santa Barbara Hacks 2019 — <em>Hacker</em>
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 blue white-text">
              🌴 LA Hacks 2019 — <em>Hacker</em>
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 deep-purple darken-4 white-text">
              🦉 AthenaHacks 2019, 2021 — <em>Mentor</em>
            </div>
          </div>
        </div>
      </div>
      <h2 className="page-heading text-center my-3">Work Experience</h2>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <div className="bg-white border px-3 pt-3 pb-0">
            <WorkExperience
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              position="Software Engineer"
              company="Stealth Startup"
              location="Los Angeles, CA"
              startDate="Feb 2024"
              endDate="Ongoing"
              responsibilities={[
                "Built the web interface platform with TypeScript, Next.js, Tailwind and MongoDB on Google Cloud Platform",
                "Led development on an AI driven voice memo and notifications systems and on the translation module's prompt engineering and integration with Deepgram, OpenAI's GPT-4 and Perplexity",
              ]}
            />
            <WorkExperience
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              position="Software Engineer II"
              company="Etsy, Inc."
              location="New York City, NY"
              startDate="Aug 2021"
              endDate="Jan 2024"
              responsibilities={[
                "Developed and A/B tested user-facing web features within the Etsy Payment and Checkout space to optimize for business metrics, with a combined impact of $12.3M in Gross Merchandise Sales, a +0.53% lift to sign ins, a 1.37% lift in checkout starts, a + 0.70% lift to 30 day reorder rate, and a +1.01% lift in Conversion Rate",
                "Led feature development for several experiments on the Listing, Cart and Checkout pages, including Buy It Now button user experience changes, login interstitial changes to incentivize sign ins, and review page optimizations",
                "Upgraded Kubeflow Pipelines to an internal fork specialized for Etsy's specific ML architecture supported by Google Cloud's Vertex AI",
              ]}
            />
            <WorkExperience
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              position="Software Engineering Intern"
              company="Etsy, Inc."
              location="Los Angeles, CA"
              startDate="June 2021"
              endDate="Aug 2021"
              responsibilities={[
                "Assisted in expanding the Klarna installments payment option to the United Kingdom, Spain and Italy",
                "Deployed PHP code to support Klarna availability in non-US regions",
                "Configured monitoring for Klarna Pay-In-3 payment usage with StatsD, Graphite and Grafana",
              ]}
            />
            <WorkExperience
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              position="DevOps Intern"
              company="Rakuten Viki"
              location="Singapore"
              startDate="May 2020"
              endDate="Aug 2020"
              responsibilities={[
                "Developed a Go Slack bot for the Spinnaker continuous delivery platform",
                "Prepared and deployed the Slack bot as a micro service with Helm on Google Kubernetes Engine (GKE)",
                "Improved Spinnaker pipeline templates by migrating to Jsonnet based templates for extended customization",
              ]}
            />
            <WorkExperience
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              position="Teaching Assistant"
              company="The University of Southern California"
              location="Los Angeles, CA"
              startDate="Jan 2019"
              endDate="May 2021"
              responsibilities={[
                "Instructed as a Teaching Assistant for ITP 303: Full-Stack Web Development",
                "Introduced students to web technologies including HTML, CSS, JavaScript, jQuery, PHP, AJAX and MySQL",
                "Advised students with their assignments and final personal projects for 4 semesters of ~80 students each",
              ]}
            />
            <WorkExperience
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              position="Course Producer"
              company="The University of Southern California"
              location="Los Angeles, CA"
              startDate="Aug 2019"
              endDate="May 2021"
              responsibilities={[
                "Instructed as a Course Producer for CSCI 102: Fundamentals of Computation",
                "Led lab sections for beginners on the core fundamentals of computer science and programming in C++",
              ]}
            />
            <WorkExperience
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              position="Software Engineering Intern"
              company="Oracle Corporation"
              location="Redwood City, CA"
              startDate="May 2019"
              endDate="Aug 2019"
              responsibilities={[
                "Developed a user behavior and click analysis utility to identify optimization points for Oracle Fusion Financials",
                "Deployed containerized Python and Shell scripts with Docker on Red Hat Enterprise Linux (Oracle Linux)",
                "Prototyped web-based visualization interface with SQL (Oracle Database) on Oracle APEX",
              ]}
            />
            <WorkExperience
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              position="Web Development Intern"
              company="Business Buddy"
              location="Singapore"
              startDate="Apr 2017"
              endDate="Jul 2017"
              responsibilities={[
                "Conducted quality assurance on Laravel based PHP web applications for company clients",
                "Tested application flow through unit testing and documented bugs on GitHub",
              ]}
            />
            <WorkExperience
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              position="Web Developer"
              company="Scout Real Estate"
              location="Beijing, China"
              startDate="Dec 2012"
              endDate="Jun 2013"
              responsibilities={[
                "Expanded company website's SEO through adjustments to its internal search feature to increase website traffic",
                "Improved user interface and user experience through integration of the Google Maps API to display properties",
              ]}
              horizontalRule={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Me;
