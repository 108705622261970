import { Link, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShapes,
  faCircleNodes,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-sm pt-0 pb-1 py-sm-2 mb-0 mb-sm-1">
        <div className="container-fluid">
          <Link to="/" id="site-title" className="navbar-brand">
            <FontAwesomeIcon icon={faShapes} /> Neuton Foo
          </Link>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item me-2">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    `btn custom-nav-button projects shadow${
                      isActive ? " selected" : ""
                    }`
                  }
                >
                  <FontAwesomeIcon icon={faCircleNodes} /> <span>Projects</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/me"
                  className={({ isActive }) =>
                    `btn custom-nav-button me shadow${
                      isActive ? " selected" : ""
                    }`
                  }
                >
                  <FontAwesomeIcon icon={faAddressCard} /> <span>Me</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="d-sm-none text-center mb-3">
        <NavLink
          to="/"
          className={({ isActive }) =>
            `btn me-2 custom-nav-button projects shadow${
              isActive ? " selected" : ""
            }`
          }
        >
          <FontAwesomeIcon icon={faCircleNodes} /> <span>Projects</span>
        </NavLink>
        <NavLink
          to="/me"
          className={({ isActive }) =>
            `btn custom-nav-button me shadow${isActive ? " selected" : ""}`
          }
        >
          <FontAwesomeIcon icon={faAddressCard} /> <span>Me</span>
        </NavLink>
      </div>
    </>
  );
};

export default Navbar;
