import "./WorkExperience.scss";

const WorkExperience = ({
  useHyphensInWorkExperience,
  position,
  subPosition,
  company,
  location,
  startDate,
  endDate,
  responsibilities,
  horizontalRule = true,
}: {
  useHyphensInWorkExperience: boolean;
  position: string;
  subPosition?: string;
  company: string;
  location: string;
  startDate: string;
  endDate: string;
  responsibilities?: string[];
  horizontalRule?: boolean;
}) => {
  return (
    <div className="work-experience">
      <div
        className={`row text-center text-sm-start ${
          responsibilities ? " mb-2" : ""
        }`}
      >
        <div className="col-12 col-sm-8 mb-2 mb-lg-0">
          <strong>
            {position} {subPosition && <small>({subPosition})</small>}
          </strong>
          <div className="small ps-0 ps-md-2">
            at{" "}
            <strong>
              <em>{company}</em>
            </strong>
          </div>
        </div>
        <div className="col-12 col-sm-4 text-center text-sm-end">
          <small>
            <em>
              {startDate} — {endDate}
            </em>
          </small>
          <br />
          <small>{location}</small>
        </div>
      </div>
      {responsibilities && (
        <div className="experience">
          <ul>
            {responsibilities.map((responsibility, responsibilityIndex) => (
              <li key={responsibilityIndex}>
                {useHyphensInWorkExperience ? "- " : ""}
                {responsibility}
              </li>
            ))}
          </ul>
        </div>
      )}
      {horizontalRule && <hr />}
    </div>
  );
};

export default WorkExperience;
